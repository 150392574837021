export const environment = {
    production: false,
    apiBaseUrl: 'http://uat.kpfs.co.in',
    apiUrl: 'https://uatapi.kpfs.co.in/api',
    environmentType: 'uat',
    dateFormat: 'DD/MM/YYYY',
    dateTimeFormat: 'DD/MM/YYYY hh:mm:ss A',
    idleTime: 300,
    idleTimeout: 300
};
